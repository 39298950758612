<template>
  <div class="son_login_form">
    <el-form :model="sonLoginForm" status-icon :rules="rules" ref="sonLoginForm" class="loginForm">
      <el-form-item prop="account">
        <el-input type="text" v-model="sonLoginForm.account" placeholder="请输入您的账号" autocomplete="off" prefix-icon="+86" class="form-item">
        </el-input>
        <img class="account-img" src="https://images.zzt.com.cn/ks/2023/05/06/account.png" alt="">
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="sonLoginForm.password" show-password placeholder="请输入您的密码" autocomplete="off" prefix-icon="+86" class="form-item">
        </el-input>
        <img class="password-img" src="https://images.zzt.com.cn/ks/2023/05/06/password.png" alt="">
      </el-form-item>
      <el-form-item prop="type" class="form-item">
        <el-checkbox label="记住账号" class="check-box" v-model="sonLoginForm.rememberAccount"></el-checkbox>
      </el-form-item>
      <el-button class="button" @click="toLogin()" :loading="sonLoginLoading">{{ sonLoginLoading?'登录中':'登录' }}</el-button>
      <p class="register" @click="$router.push(`/register?redirect=${redirect}`)">还没有账号？ 立即注册！</p>
    </el-form>
  </div>
</template>

<script>
import { encode, decode } from 'js-base64';
export default {
  data() {
    return {
      sonLoginLoading: false,
      redirect: this.$route.query?.redirect,
      sonLoginForm: {
        account: '',
        password: '',
        sysAccountType: 0,
        rememberAccount: true,
      },
      rules: {
        account: [
          { required: true, message: '请输入您的账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入您的密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    toLogin() {
      this.$refs.sonLoginForm.validate((valid) => {
        if (valid) {
          this.sonLoginLoading = true
          this.$store.dispatch('login', this.sonLoginForm).then((res) => {
            this.sonLoginLoading = false
            if (this.sonLoginForm.rememberAccount) {
              let subAccount = {
                userName: this.sonLoginForm.account,
                passWord: encode(this.sonLoginForm.password)
              }
              localStorage.setItem('ZZT-SUBACCOUNT', JSON.stringify(subAccount))
            } else {
              localStorage.removeItem('ZZT-SUBACCOUNT')
            }
            if (this.redirect) {
              setTimeout(() => {
                window.location.href = `${this.redirect}${this.redirect.includes('?') ? '&' : '?'}token=${res}`
              }, 300);
            } else {
              this.$router.push({ path: '/productcenter' })
            }
          }).catch(err => {
            this.sonLoginLoading = false
            this.$refs.sonLoginForm.fields[1].validateMessage = err
            this.$refs.sonLoginForm.fields[1].validateState = 'error'
          })
        }
      })
    },
    fillInAccount() {
      //子账号
      let { userName, passWord } = JSON.parse(localStorage.getItem('ZZT-SUBACCOUNT')) || {}
      if (userName && passWord) {
        this.sonLoginForm.account = userName
        this.sonLoginForm.password = decode(passWord)
      }
    }
  },
  mounted() {
    this.fillInAccount()
  }
}
</script>

<style lang="scss" scoped>
.loginForm {
  .code {
    position: relative;
    .captcha {
      width: Translate(80px);
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .error {
      position: absolute;
      color: #f56c6c;
      bottom: -35px;
      font-size: Translate(12px);
      line-height: Translate(20px) px;
      font-weight: 400;
    }
    .get-code {
      position: absolute;
      font-size: Translate(14px);
      color: #4088eb;
      font-weight: 400;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: #fff;
      padding: 0;
    }
  }
  .button {
    position: relative;
    height: Translate(42px);
    color: #ffffff;
    text-align: center;
    border-radius: Translate(4px);
    background-color: #4088eb;
    cursor: pointer;
    border: none;
    width: 100%;
    &:hover {
      background: #337ee5;
      color: #fff;
    }
  }
  .register {
    margin-top: Translate(29px);
    font-size: Translate(14px);
    color: #4088eb;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
  }
  .form-item {
    border-radius: Translate(2px);
    .check-box {
      float: left;
    }
  }
}
.password-img,
.account-img {
  position: absolute;
  width: 28px;
  left: 20px;
  top: calc(50% - 15px);
}
</style>