<template>
  <div class="wrapper" v-loading="loading">
    <div class="login-container">
      <div class="loginform-container">
        <div class="check-login-mode" @click="isParentLogin = !isParentLogin">
          <i class="iconfont icon-qiehuan"></i>
          {{ isParentLogin ? '子账号登录' : '主账号登录' }}
        </div>
        <p class="title">{{ isParentLogin ? '主账号登录' : '子账号登录' }}</p>
        <template v-if="isParentLogin">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
            <el-tab-pane label="密码登录" name="passWord">
              <el-form :model="basePassWordForm" status-icon :rules="rules" ref="basePassWordForm" class="loginForm">
                <el-form-item prop="count">
                  <el-input type="text" v-model="basePassWordForm.count" placeholder="请输入您的账号" autocomplete="off"
                    prefix-icon="+86" class="form-item">
                  </el-input>
                  <img class="account-img" src="https://images.zzt.com.cn/ks/2023/05/06/account.png" alt="">
                </el-form-item>
                <el-form-item prop="passWord">
                  <el-input type="password" v-model="basePassWordForm.passWord" show-password placeholder="请输入您的密码"
                    autocomplete="off" prefix-icon="+86" class="form-item">
                  </el-input>
                  <img class="password-img" src="https://images.zzt.com.cn/ks/2023/05/06/password.png" alt="">
                </el-form-item>
                <el-form-item prop="type" class="form-item">
                  <el-checkbox label="记住账号" class="check-box" v-model="basePassWordForm.rememberAccount"></el-checkbox>
                </el-form-item>
                <el-button class="button" @click="submitBasePassWordForm('basePassWordForm')"
                  :loading="parentLoginLoading">{{ parentLoginLoading ? '登录中' : '登录' }}</el-button>
                <p class="register" @click="$router.push(`/register?redirect=${redirect}`)">还没有账号？ 立即注册！</p>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="短信登录" name="shortMessage">
              <el-form :model="baseShortMessageForm" status-icon :rules="rules" ref="baseShortMessageForm"
                class="loginForm">
                <el-form-item prop="telNumber" id="tel">
                  <el-input type="text" v-model="baseShortMessageForm.telNumber" placeholder="请输入您的手机号"
                    prefix-icon="+86" maxLength="11" autocomplete="off" class="form-item"></el-input>
                </el-form-item>
                <el-form-item prop="captchaCode" class="code">
                  <el-input type="text" v-model="baseShortMessageForm.captchaCode" placeholder="请输入图形验证码"
                    autocomplete="off" class="code-item" @input="handleInput"></el-input>
                  <img :src="captchaSrc" class="captcha cursor-pointer" @click="getCaptcha">
                </el-form-item>
                <el-form-item prop="telCode" class="code">
                  <el-input type="text" v-model="baseShortMessageForm.telCode" placeholder="请输入手机验证码" autocomplete="off"
                    class="code-item"></el-input>
                  <el-button v-if="!isError" :disabled="baseShortMessageForm.telNumber.length !== 11 || hasSend"
                    class="get-code" @click.prevent="getCode">
                    {{ sendBtnText }}
                  </el-button>
                  <el-button disabled class="get-code" style="color: #bbb;" v-else>
                    {{ sendBtnText }}
                  </el-button>
                </el-form-item>
                <el-form-item prop="type" class="form-item">
                  <el-checkbox label="记住账号" class="check-box"
                    v-model="baseShortMessageForm.rememberAccount"></el-checkbox>
                </el-form-item>
                <el-button class="button" @click.prevent="submitBaseShortMessageForm('baseShortMessageForm')"
                  :loading="msgLoginLoading">{{ msgLoginLoading ? '登录中' : '登录' }}</el-button>
                <p class="register" @click="$router.push(`/register?redirect=${redirect}`)">还没有账号？ 立即注册！</p>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </template>
        <template v-else>
          <sonLoginForm ref="sonLoginFormRef" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import sonLoginForm from "./components/sonLoginForm.vue";
import { encode, decode } from 'js-base64';
export default {
  components: { sonLoginForm },
  created() {
    this.redirect = this.getJumpUrl()
    //恢复登录状态
    this.recoveryLogin()
  },
  mounted() {
    console.log('this.$route', this.$route)
    this.fillInAccount()
    this.getCaptcha()
    document.onkeyup = e => {
      if (e.keyCode === 13 && e.target.baseURI.match(/login/)) {
        // 调用登录 验证方法
        if (this.isParentLogin) {
          //主账号
          this.activeName === 'passWord' ? this.submitBasePassWordForm('basePassWordForm') : this.submitBaseShortMessageForm('baseShortMessageForm')
        } else {
          //子账号
          this.$refs.sonLoginFormRef.toLogin()
        }
      }
    }
  },
  name: 'Login',
  data() {
    const CheckPhone = async (rule, value, callback) => {
      if (value) {
        if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式错误"));
        }
      }
    };
    return {
      loading: false,
      parentLoginLoading: false,
      msgLoginLoading: false,
      isParentLogin: true,//是否主账号登录
      redirect: '', // 登录后的重定向地址
      activeName: 'passWord',
      captchaSrc: '',
      visible: false,
      isError: true,
      basePassWordForm: {
        count: '',
        passWord: '',
        rememberAccount: true
      },
      baseShortMessageForm: {
        telNumber: '',
        telCode: '', //手机验证码
        captchaCode: '', //图形验证码
        rememberAccount: true
      },
      hasSend: false, //是否已经发送验证码
      countDown: 60, //倒计时
      rules: {
        count: [
          { required: true, message: '请输入您的账号', trigger: 'blur' }
        ],
        passWord: [
          { required: true, message: '请输入您的密码', trigger: 'blur' }
        ],
        telNumber: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' },
          { validator: CheckPhone, trigger: "blur" },
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' },
          // { validator: CheckcaptchaCode, trigger: "input" },
        ],
        telCode: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    sendBtnText() {
      return this.hasSend ? `${this.countDown}s` : '发送验证码'
    }
  },
  methods: {
    getJumpUrl() {
      // 获取重定向地址
      let params = this.$route.query
      let url = params?.redirect ? params.redirect : ''
      Object.keys(params)?.forEach((key) => {
        if (key !== 'redirect') {
          url = `${url}${url.includes('?') ? '&' : '?'}${key}=${params[key]}`
        }
      })
      return url
    },
    async handleInput(value) {
      //当图形验证码和手机号填写完毕时自动发送验证码
      if (value.length === 4) {
        const { success } = await api.checkCaptcha({ captcha: this.baseShortMessageForm.captchaCode })
        if (success) {
          // todo: 让后端把注册接口这个字段改为非必填
          // this.form.content = '注册申请'
          this.$refs['baseShortMessageForm'].clearValidate('captchaCode')
          this.isError = false
        } else {
          if (this.isError) {
            this.$refs['baseShortMessageForm'].fields[1].validateMessage = '验证码错误'
            this.$refs['baseShortMessageForm'].fields[1].validateState = 'error'
            this.getCaptcha()
          }
        }
      }
    },
    async getCaptcha() {
      const { data } = await api.getCaptcha()
      const url = window.URL.createObjectURL(new Blob([data], { type: 'image/jepg' }))
      this.captchaSrc = url
    },
    getCode() {
      this.hasSend = true
      const timer1 = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
        } else {
          this.hasSend = false
          this.countDown = 60
          clearInterval(timer1)
        }
      }, 1000)
      api.verificationCodeSend({
        mobile: this.baseShortMessageForm.telNumber,
        temId: 4
      }).then(({ code }) => {
        if (code === '0000') {
          message.success('验证码已发送')
        }
      })
    },
    submitBaseShortMessageForm(formName) {
      let validateFieldList = [];
      this.$refs[formName].validateField(
        ["telNumber", "telCode"],
        (valid) => {
          if (!valid) {
            //校验通过走这里,每通过一次,会往这个数组里加一个""
            validateFieldList.push(valid);
            if (
              validateFieldList.length == 2 &&
              validateFieldList.every((item) => item === "" && !this.isError)
            ) {
              this.msgLoginLoading = true
              //这里写校验通过的业务逻辑
              this.$store.dispatch('loginBaseCode', this.baseShortMessageForm).then((res) => {
                this.msgLoginLoading = false
                // 如果用户勾选了“记住账号”，则保存用户名到localStorage中
                if (this.baseShortMessageForm.rememberAccount) {
                  localStorage.setItem('ZZT-LOGINPHONE', this.baseShortMessageForm.telNumber)
                } else {
                  // 否则清除localStorage中保存的用户名
                  localStorage.removeItem('ZZT-LOGINPHONE')
                }
                if (this.redirect) {
                  setTimeout(() => {
                    window.location.href = `${this.redirect}${this.redirect.includes('?') ? '&' : '?'}token=${res}`
                  }, 300);
                } else {
                  this.$router.push({ path: '/productcenter' })
                }
              }).catch((msg) => {
                this.msgLoginLoading = false
                // this.$message.error(msg)
                //主动触发指定项表单校验
                this.$refs[formName].fields[2].validateMessage = msg
                this.$refs[formName].fields[2].validateState = 'error'
              })
            }
          }
        })
    },
    submitBasePassWordForm(formName) {
      this.$refs.basePassWordForm.validate((valid) => {
        if (valid) {
          let formData = {
            account: this.basePassWordForm.count.trim(),
            password: this.basePassWordForm.passWord.trim(),
          }
          this.parentLoginLoading = true
          this.$store.dispatch('login', formData).then((res) => {
            this.parentLoginLoading = false
            // 如果用户勾选了“记住账号”，则保存用户名到localStorage中
            if (this.basePassWordForm.rememberAccount) {
              let mainAccount = {
                userName: this.basePassWordForm.count,
                passWord: encode(this.basePassWordForm.passWord)
              }
              localStorage.setItem('ZZT-MAINACCOUNT', JSON.stringify(mainAccount))
            } else {
              // 否则清除localStorage中保存的用户名
              localStorage.removeItem('ZZT-MAINACCOUNT')
            }
            if (this.redirect) {
              setTimeout(() => {
                window.location.href = `${this.redirect}${this.redirect.includes('?') ? '&' : '?'}token=${res}`
              }, 300);
            } else {
              this.$router.push({ path: '/productcenter' })
            }
          }).catch((msg) => {
            this.parentLoginLoading = false
            // this.$message.error(msg)
            //主动触发指定项表单校验
            this.$refs[formName].fields[1].validateMessage = msg
            this.$refs[formName].fields[1].validateState = 'error'
          })
        }
      })
    },
    handleClick(tab) {
      this.activeName = tab.name
    },
    recoveryLogin() {
      this.loading = true
      let isJumpLogin = this.$route.query?.redirect //是否跳转登录
      const { token, isLogin } = JSON.parse(window.localStorage.getItem('ZZT_SSO')) || {}
      if (isJumpLogin) {
        //判断token是否有效，有效直接免登录携带token跳转
        api.ifValidToken().then(({ success, data }) => {
          if (success && data.data) {
            //token有效 免登录
            window.location.href = `${this.redirect}${this.redirect.includes('?') ? '&' : '?'}token=${token}`
            this.loading = false
          } else {
            //重新登录
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.loading = false
        if (token && isLogin) {
          this.$router.push('/productcenter')
        }
      }
    },
    fillInAccount() {
      //主账号
      let { userName, passWord } = JSON.parse(localStorage.getItem('ZZT-MAINACCOUNT')) || {}
      if (userName && passWord) {
        this.basePassWordForm.count = userName
        this.basePassWordForm.passWord = decode(passWord)
      }
      //短信
      let loginPhone = localStorage.getItem('ZZT-LOGINPHONE')
      loginPhone ? this.baseShortMessageForm.telNumber = loginPhone : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: rgb(225, 237, 251);
  width: 100%;
  height: 100%;
}

.login-container {
  // position: relative;
  width: 100vw;
  max-width: Translate(1920px);
  height: Translate(1080px);
  max-height: calc(100vh - Translate(60px));
  background: url('https://images.zzt.com.cn/ox/2023/03/03/ditu-denglu.png') no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-position: 0 Translate(-140px);

  .loginform-container {
    width: Translate(420px);
    height: Translate(520px);
    box-sizing: border-box;
    padding: Translate(42px) Translate(50px);
    position: absolute;
    top: Translate(195px);
    left: 65%;
    background-color: #fff;

    .check-login-mode {
      position: absolute;
      right: Translate(14px);
      top: Translate(10px);
      font-size: Translate(14px);
      cursor: pointer;
      color: #999999;

      &:hover {
        color: #4088eb;
      }
    }

    .loginForm {
      .code {
        position: relative;

        .captcha {
          width: Translate(80px);
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        .error {
          position: absolute;
          color: #f56c6c;
          bottom: -35px;
          font-size: Translate(12px);
          line-height: Translate(20px) px;
          font-weight: 400;
        }

        .get-code {
          position: absolute;
          font-size: Translate(14px);
          color: #4088eb;
          font-weight: 400;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          border: none;
          background: #fff;
          padding: 0;
        }
      }

      .button {
        position: relative;
        height: Translate(42px);
        color: #ffffff;
        text-align: center;
        border: none;
        border-radius: Translate(4px);
        background-color: #4088eb;
        cursor: pointer;
        width: 100%;

        &:hover {
          background: #337ee5;
          color: #fff;
        }
      }

      .register {
        margin-top: Translate(29px);
        font-size: Translate(14px);
        color: #4088eb;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
      }

      .form-item {
        border-radius: Translate(2px);

        .check-box {
          float: left;
        }
      }
    }

    .title {
      font-size: Translate(24px);
      color: #333333;
      font-weight: 500;
      margin-bottom: Translate(29px);
    }

    .nav-list {
      width: Translate(162px);
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      margin-top: Translate(35px);
      font-size: Translate(14px);
      color: #333333;
      line-height: Translate(14px);
      font-weight: 500;
    }
  }
}

#tel::before {
  position: absolute;
  content: '+86';
  display: block;
  width: Translate(26px);
  height: Translate(14px);
  left: Translate(8px);
  top: Translate(13px);
  z-index: 10;
  font-size: Translate(14px);
  color: #333333;
  line-height: Translate(14px);
  font-weight: 400;
}

.password-img,
.account-img {
  position: absolute;
  width: 28px;
  left: 20px;
  top: calc(50% - 15px);
}
</style>

<style lang="scss">
/* 重写element-ui部分样式 */
.tabs {
  .el-tabs__active-bar {
    background-color: #333;
    width: 24px !important;
    position: absolute;
    left: 10%;
  }

  .el-tabs__item.is-active {
    color: #333;
  }

  .el-tabs__item {
    color: #999;
    position: relative;
  }

  .el-tabs__item:hover {
    color: #333;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs {
    display: flex;
    flex-direction: column;
  }

  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}

.tabs {
  .el-input__prefix:after {
    position: relative;
    background: #d9d9d9;
    left: 24px;
    top: -26px;
    content: '';
    display: block;
    width: 1px;
    height: 10px;
    line-height: 14px;
  }

  .el-input__inner {
    padding-left: 38px;
  }

  .code-item .el-input__inner {
    padding-left: 15px;
  }
}

#tel {
  .el-input__prefix:after {
    position: relative;
    background: #d9d9d9;
    left: 34px;
    top: -26px;
    content: '';
    display: block;
    width: 1.5px;
    height: 10px;
    line-height: 14px;
  }

  .el-input--prefix .el-input__inner {
    padding-left: 48px;
  }
}

.el-input__suffix .el-input__validateIcon {
  display: none;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #606266;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #4088eb;
  border-color: none;
}

.el-checkbox input.is-focus .el-checkbox__inner {
  border-color: #606266;
}

.el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
</style>
